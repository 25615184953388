<template>
  <!--为echarts准备一个具备大小的容器dom-->
  <div
    class="line"
    :id="Line"
    :style="{ height: lineStyle.height, width: lineStyle.width }"
  />
</template>
<script>
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
export default {
  name: "smoothLine",
  props: [
    "id",
    "lineStyle",
    "optionSeries",
    "xAxisOption",
    "tooltips",
    "legend",
    "grid",
  ],
  data() {
    return {
      Line: this.id || "Line",
      charts: "",
    };
  },
  // 实时监听父组件传过来的值重绘柱状图
  watch: {
    optionSeries: {
      handler(value) {
        this.drawLine();
      },
      deep: true,
    },
    xAxisOption: {
      handler(value) {
        this.drawLine();
      },
      deep: true,
    },
    tooltips: {
      handler(value) {
        this.drawLine();
      },
      deep: true,
    },
    legend: {
      handler(value) {
        this.drawLine();
      },
      deep: true,
    },
    grid: {
      handler(value) {
        this.drawLine();
      },
      deep: true,
    },
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      this.charts = echarts.init(document.getElementById(this.Line));
      this.charts.clear();
      this.charts.setOption({
        xAxis: {
          type: "category", //类目轴
          splitLine: {
            show: false,
          },
          // boundaryGap: true,
          data: this.xAxisOption.xAxisData,
          itemStyle: {
            //修改柱条颜色
            color: "#cdddfd",
          },
          axisLine: {
            lineStyle: {
              color: "#F0F0F0", //坐标轴颜色
            },
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 0.65)", //坐标值得具体的颜色
            },
            interval: this.xAxisOption.xAxisInterval, //坐标分割
          },
          axisTick: {
            show: false, //不显示刻度
          },
        },
        yAxis: {
          type: "value",
          show: false,
          //   splitLine: {
          //     show: false,
          //   },
        },
        grid: this.grid,
        tooltip: this.tooltips,
        legend: this.legend,
        series: this.optionSeries,
      });
    },
  },
};
</script>
